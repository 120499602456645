// extracted by mini-css-extract-plugin
export var almond = "thank-you-module--almond--gFZwz";
export var buttonWrapper = "thank-you-module--button-wrapper--NpuhS";
export var calendlyContainer = "thank-you-module--calendly-container--zgmqe";
export var calendlySection = "thank-you-module--calendly-section--ulR0l";
export var headshot = "thank-you-module--headshot--WdNtd";
export var headshotOverlap = "thank-you-module--headshot-overlap--Zg1tY";
export var hero = "thank-you-module--hero--6K5r2";
export var seaGlass = "thank-you-module--sea-glass--zNJtR";
export var sectionContent = "thank-you-module--section-content--dycpB";
export var subheading = "thank-you-module--subheading--IKdfB";